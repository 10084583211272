import React from 'react'
import "normalize.css"
import Layout from './components/layout'
import { Link, graphql, useStaticQuery } from 'gatsby'
import blogStyles from '../pages/sass/blog.module.scss'
import Head from '../pages/components/head'

export const BlogPage = () => {
    const data = useStaticQuery(graphql`
        query {
            site{
                siteMetadata {
                    author
                }
            }
            allContentfulBlogPost(
                sort: {
                    fields: publishedDate,
                    order: DESC
                }
            ) {
                edges {
                    node {
                        title
                        subtitle
                        slug
                        publishedDate (formatString:"MMMM Do, YYYY")
                    }
                }
            }
        }
    `)

    return (
        <Layout>
            <Head title="Blog"/>
            <div className={blogStyles.sectionBlog}>
                <div>
                    <img src={require('./posts/assets/me.jpg')} className={blogStyles.blogImage} alt="me"></img>
                    <p className={blogStyles.blogTitle}>Personal blog by <br></br> {data.site.siteMetadata.author}</p>
                </div>
                <ol className={blogStyles.posts}>
                    {data.allContentfulBlogPost.edges.map((edge) => {
                        return (
                            <li className={blogStyles.post}>
                                <Link to={`/blog/${edge.node.slug}`}>
                                    <h2>{edge.node.title}</h2>
                                    <p>{edge.node.subtitle}</p>
                                    <p className={blogStyles.date}>{edge.node.publishedDate}</p>
                                </Link>
                            </li>
                        )
                    })}
                </ol>
            </div>
        </Layout>
    )
}

export default BlogPage